import OBJ from './_obj';
import {Util} from './_util';
import { gsap } from "gsap";

class nav {
  constructor(){
		const A = this;

    this.humNav();
		this.pageTop();
		this.headerMin();
		this.pageCurrent();
		this.membersCurrent();


		// if($('.top-page').length){
    //   this.ankerSet();
    // }

		this.loadAnker();

  }


	headerMin(){
		const $header = $('.l-header');

		$(window).on('scroll', (e) => {
			const val = $(e.currentTarget).scrollTop();

			if(50 < val){
				$header.addClass('is-min');
			} else {
				$header.removeClass('is-min');
			}
		}).trigger('scroll');
	}

	pageCurrent(){
		const path = location.pathname.split('/')[1];
		const $navMainLiPC = $('.l-header__nav__main li');
		const $navOtherLiPC = $('.l-header__nav__other li');

		if(path){
			if(path == 'contact' || path == 'consultant'){
				$navOtherLiPC.find('.' + path).addClass('is-current');
				if(path == 'consultant'){
					$('.l-header__nav__lock li.blue').addClass('is-none');
					$('.l-header__nav__lock li.green').removeClass('is-none');
				}
			} else {
				$navMainLiPC.find('.' + path).addClass('is-current');
			}
		} else {
			$navMainLiPC.find('.top').addClass('is-current');
		}
		// switch (path){
		// 	case 'consultant':
		// 		$('.l-header__nav__lock li.blue').addClass('is-none');
		// 		$('.l-header__nav__lock li.green').removeClass('is-none');
		// 		break;
		// }

		// console.log(path);
	}

	membersCurrent(){
		const path = location.pathname.split('/');

		// console.log(path);

		path.forEach((value,i) => {
			// console.log(i,value);
			if(value == 'member'){
				$('.l-header__nav__lock li').addClass('is-login');
				$('.l-header__nav__lock li a').text('ログイン中');
			}
		});
	}


  humNav() {
    const $humBtn = $('.l-hum-btn');
    const $body = $('body');
		const $wrap = $('#wrap');
		const $grayLayer = $('.l-sp-nav__graylayer');
    let scrVal;

    $humBtn.on('click', (e) => {
      if($body.hasClass('is-nav-op')){
				$body.removeClass('is-nav-op');
				$wrap.css('top', 'auto');
				$(window).scrollTop(scrVal);

			} else {
				scrVal = $(window).scrollTop();

				$body.addClass('is-nav-op');
				$wrap.css('top', -scrVal);

			}
    });

		$grayLayer.on('click', (e) => {
			$humBtn.trigger('click');
		});

  }


  pageTop() {
		if($('body').hasClass('maintenance-page')) return;

    const $btn = $('.l-footer__pagetop');
    const btnBottom = $btn.css('bottom').replace( /px/g , '' );
		let scrVal = 0;

    $(window).on('scroll', (e) =>{
			const $this = $(e.currentTarget);
      const val = $this.scrollTop();
			const btnH = $btn.height();
      const footerPosi = $('.l-footer').offset().top;
      const wH = window.innerHeight;

      if(footerPosi - wH <= val){
        $btn.addClass('is-absolute');
      } else {
        $btn.removeClass('is-absolute');
      }

			if(100 < val){
				$btn.addClass('is-active');
			} else {
				$btn.removeClass('is-active');
			}

    }).trigger('scroll');
  }

  ankerSet(){
    const $btnAnker = $('.l-side-nav .to-anker');

    $btnAnker.each((i,el) => {
      let $this = $(el);
      // $this.attr('href', $this.attr('href').replace(new RegExp(OBJ.DIR, 'g'), ''));
      $this.attr('href', $this.attr('href').split('/')[5]);

    });


    $btnAnker.on('click', (e) => {
      if(OBJ.BP < $(window).width()) return;
      e.preventDefault();
      $('.hum-btn').trigger('click');
    });
  }


  loadAnker(){
    const hash = location.hash;
    const url = location.pathname;

    if(!hash || !$(hash).length) return;

    let speed = .2;
    let hH = $('.l-header').outerHeight();
    let position = $(hash).offset().top - hH;
    gsap.to($('html, body'), { scrollTop: position, ease: "power1.in", duration: speed});

    history.replaceState('','',url);

  }


}

export {nav};