import gsap from 'gsap';
import jqueryValidation from "jquery-validation";
import additionalMethods from "jquery-validation/dist/additional-methods";
import {Util} from '../../_util';

const contactPage = () => {
	const obj = {
		init(){

			$('input').on('keypress',(e) => {
				// console.log(e);
        if(e.which == 13) {
            return false;
        }
    	});
			this.formValidate();
			this.uploadFileTxt();
			this.checkOpen();
			this.formSession.init();


			$('.btn-form-back a').on('click', (e) => {
				e.preventDefault();
			});

			$('.p-upload__input-delete').on('click', (e) => {
				const $input = $(e.currentTarget).parents('.p-upload').find('input');
				const $name = $(e.currentTarget).parents('.p-upload').find('.p-upload__input-name');
				$input.val(null);
				$name.text('選択されていません');
			});


		},

		uploadFileTxt(){
			const A = this;
			const $inputUpload = $('.p-upload__input');

			$inputUpload.on('change', (e) => {
				const $this = $(e.currentTarget);
				const file = $this.prop('files')[0];
				const size = file.size;
				$this.next('.p-upload__input-name').text(file.name + ' ' + A.ag2fileSizeOpt(size,true,1));
			});
		},

		checkOpen(){
			const A = this;
			const $check = $('.input-wrap .p-input-checkbox');

			$check.on('change', (e) => {
				const $this = $(e.currentTarget);
				if($this.prop('checked')){
					$this.parents('.input-wrap').find('.p-select').removeClass('u-no-click');
				} else {
					$this.parents('.input-wrap').find('.p-select').addClass('u-no-click');
				}
			});

		},


		formValidate(){
			// $.validator.setDefaults({
			// 	debug: true,
			// 	success: "valid"
			// });
			$.validator.addMethod("regex",function(value, element, regexp) {
					let check = false;
					return this.optional(element) || regexp.test(value);
				},
				// メッセージを省略
			);
			$.validator.addMethod('fileSizeLimit', function(value, element, limit) {
				return !element.files[0] || (element.files[0].size <= limit);
			}, 'File is too big');

			let validator = $('#contact-form').validate({
				rules: {
					contactItem: {
						required: true,
					},
					name: {
						required: true,
					},
					kana: {
						required: true,
						regex : /^[ぁ-んー]+$/,
					},
					email: {
						required: true,
						email: true
					},
					tel: {
						number: true,
					},
					fax: {
						number: true,
					},
					contents: {
						required: true,
					},
					file1: {
						fileSizeLimit: 5000000,
						extension: 'jpg|jpeg|png|gif|pdf|zip|lzh|xdw|doc|docx',

					},
					file2: {
						fileSizeLimit: 5000000,
						extension: 'jpg|jpeg|png|gif|pdf|zip|lzh|xdw|doc|docx',
					},
					file3: {
						fileSizeLimit: 5000000,
						extension: 'jpg|jpeg|png|gif|pdf|zip|lzh|xdw|doc|docx',
					},
				},
				messages: {
					contactItem: {
						required: '※必須項目が入力されていません',
					},
					name: {
						required: '※必須項目が入力されていません',
					},
					kana: {
						required: '※必須項目が入力されていません',
						regex : '※ひらがなでご入力ください',
					},
					email: {
						required: '※必須項目が入力されていません',
						email: '※メールアドレスの形式が正しくありません'
					},
					tel: {
						number: '※半角数（ハイフンなし）でご入力ください',
					},
					fax: {
						number: '※半角数（ハイフンなし）でご入力ください',
					},
					contents: {
						required: '※必須項目が入力されていません',
					},
					file1: {
						fileSizeLimit: '※ファイルが大きすぎます',
						extension: '※対応していない拡張子です'
					},
					file2: {
						fileSizeLimit: '※ファイルが大きすぎます',
						extension: '※対応していない拡張子です'
					},
					file3: {
						fileSizeLimit: '※ファイルが大きすぎます',
						extension: '※対応していない拡張子です'
					},
				},
				errorPlacement: function(error, element){
					let errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					element.addClass('is-invalid');
					const errorP = $('<p>').text(error[0].innerText);
					const errorDiv = $('<div class="invalid-feedback" id="error_' + errorKey + '">').append(errorP);

					// console.log(element);
					if(element.hasClass('p-upload__input')){
						element.parents('.p-upload').append(errorDiv);
					} else {
						element.parents('.form-list__input-block').append(errorDiv);
					}
				},
				success: function(error, element) {
					let errorKey = $(element).attr('id') + 'Error';
					$('#error_' + errorKey).remove();
					$(error).remove();
					$(element).removeClass('is-invalid');
					$(element).removeClass('error');
				},
				invalidHandler: (event,validator) => {
					const util = new Util();
					util.ankerScroll('contact-form');
				},
				submitHandler: (form) => {
					nameReset();
					$(form).ajaxSubmit();
				}
			});


			function nameReset(){
				$('#file1').attr('name', 'upfile[]');
				$('#file2').attr('name', 'upfile[]');
				$('#file3').attr('name', 'upfile[]');

				$('#field01').attr('name', 'field[]');
				$('#field02').attr('name', 'field[]');
				$('#field03').attr('name', 'field[]');
				$('#field04').attr('name', 'field[]');
			}
		},

		ag2fileSizeOpt(a,b,c){
			let thisSize, fileUnit, thisUnit;

			//数値に変換
			thisSize = Number(a);
			//数値に変換できなかった場合
			if(isNaN(thisSize)) return 'Error : Not a Number.';
			//小数点を含めている場合
			if(String(thisSize).split('.').length > 1) return 'Error : Unaccetable Number.';


			//基準のバイト数と単位の配列を設定
			if(b){
				b = 1000;
				fileUnit = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			}else{
				b = 1024;
				fileUnit = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			}

			//有効小数点 デフォルト小数第2位まで(小数第3位で四捨五入)
			if(c !== 0){c = c ? c : 2;}

			if(thisSize >= b){
				for(let i = 0, j = 0, sizeTemp = (thisSize / b); sizeTemp >= 1 && j < fileUnit.length; i++, j++, sizeTemp /= b){
					thisUnit = i;
					thisSize = sizeTemp;
				}
				thisSize = (Math.round(thisSize * (10**c))/(10**c)) + fileUnit[thisUnit];
			}else{
				if(a === 1) thisUnit = 'byte';
				else thisUnit = 'bytes';
				thisSize = a+' '+thisUnit;
			}

			//変換した表記の文字列を返す
			return thisSize;
		},

		formSession: {
			init(){
				const A = this;
				$('input, select, textarea').on('change', (e) => {
					A.post($(e.currentTarget));
				});

				// A.objSet();
				A.set();
			},

			post(_el){
				if(_el.attr('type') == 'checkbox'){
					const checked = _el.prop('checked');
					sessionStorage.setItem(_el.attr('name'),checked);
				} else {
					sessionStorage.setItem(_el.attr('name'), _el.val());
				}
			},

			set(){
				const _self = this;

				this.keyArray.forEach((el,i) => {

					const tag = $('*[name="'+ el +'"]').prop('tagName');
					if(sessionStorage.getItem(el)){
						const val = sessionStorage.getItem(el);
						if($('input[name="'+ el +'"]').attr('type') == 'checkbox'){
							// console.log(el,'チェックボックス', val)
							if(val == 'true'){
								$('input[name="'+ el +'"]').prop('checked', true).trigger('change');
							}
						} else if(el == 'contactItem'){
							// radio
							$('input[name="contactItem"]').each((i,el) => {
								if($(el).val() == val){
									$(el).prop('checked', true);
								}
							});
						} else if(el == 'member'){
							// radio
							$('input[name="member"]').each((i,el) => {
								if($(el).val() == val){
									$(el).prop('checked', true);
								}
							});
						} else {
							if(tag == 'TEXTAREA'){
								$('textarea[name="'+ el +'"]').text(val);
							} else if(tag == 'SELECT'){
								$('select[name="'+ el +'"]').val(val);
							} else {
								$('input[name="'+ el +'"]').val(val);
							}
						}


					}
				});
			},

			keyArray: [
				'contactItem',
				'company',
				'dep',
				'name',
				'kana',
				'member',
				'email',
				'tel',
				'fax',
				'contents',
				'field01',
				'field02',
				'field03',
				'field04',
				'structural01',
				'select01',
				'structural02',
				'select02',
				'structural03',
				'select03',
				'structural04',
				'select04',
				'structural05',
				'select05',
				'structural06',
				'select06',
				'damage01',
				'select07',
				'damage02',
				'select08',
				'damage03',
				'select09',
			]
		}
	}

	obj.init();
}
export {contactPage};