
import OBJ from './_obj';
import {ua} from './_ua';
import {Util} from './_util';
import {nav} from './_nav';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
// import Vue from 'vue';
// import headerSpNav from './layouts/headerSpNav.vue'
// import footer from'./layouts/footer.vue';
import '../scss/main.scss';
// import '../scss/pages/index/style.scss';

import {contactPage} from './pages/contact/_script';


$(function(){
	new ua();
	new nav();
	const util = new Util();

	util.hMax('js-h-max');
	util.contentMinH();
	util.infoEnd();


	$('a[href^="#"]').on('click',(e)=>{
    e.preventDefault();
    const $this = $(e.currentTarget);
		const id = $this.attr('href').split('#')[1];
    const diff = $this.data('diff');

		if($this.hasClass('non-scroll') || $this.hasClass('js-modal-btn') || $this.hasClass('js-video-modal-btn')) return;
    util.ankerScroll(id,diff);
  });

	// new Vue({
	// 	el: '#vue-header',
	// 	render: (h) => h(headerSpNav),
	// });
	// new Vue({
	// 	el: '#vue-footer',
	// 	render: (h) => h(footer),
	// });

	const pagesJs = () => {
		const $body = document.querySelector('body');

		if($body.classList.contains('contact-page')){
			contactPage();
		}
	}

	pagesJs();

});
