import gsap from 'gsap';

class Util {
	constructor(){
		// console.log('util');
	}

	ankerScroll(_id, _diff){
		let hH = document.querySelector('.l-header').offsetHeight;
		// let hH = 0;

		if(_diff){
			hH = _diff;
		}

		const target = _id ? document.getElementById(_id) : 0;
		const rect = target ? target.getBoundingClientRect() : 0;
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const offsetTop = rect ? rect.top + scrollTop : 0;
		const position = offsetTop - hH;
		const duration = 0.7;

		gsap.to($('html, body'), { scrollTop: position, ease: "power2.out", duration});

	}

	bgImgSet(_el){
		const el = document.querySelectorAll(_el);

		el.forEach(el => {
			const src = el.querySelector('img').src;

			el.style.backgroundImage = 'url("' + src + '")';

		});
	}

	contentMinH(){
		const $el = document.getElementsByClassName('main-content')[0];
		const $header = document.getElementsByClassName('l-header')[0];
		const $footer = document.getElementsByClassName('l-footer')[0];
		const $body = document.getElementsByTagName('body')[0];
		let hH =  $header.offsetHeight;
		let fH =  $footer.offsetHeight;
		let bH = $body.offsetHeight;
		let wH = window.innerHeight;
		let timer = 0;

		console.log($header,hH);
		console.log($footer,fH);
		console.log($body,bH);

		window.addEventListener('load', () => {
			if(bH < wH){
				$el.style.height = (wH - hH - fH) + 'px';
			} else {
				$el.style.height = 'auto';
			}
		}, false);

		window.addEventListener('resize', () => {
			clearTimeout(timer);

			timer = setTimeout(() => {
				let hH =  $header.offsetHeight;
				let fH =  $footer.offsetHeight;
				let bH = $body.offsetHeight;
				let wH = window.innerHeight;
				if(bH < wH){
					$el.style.height = (wH - hH - fH) + 'px';
				} else {
					$el.style.height = 'auto';
				}
			},200);
		}, false);
	}

	hMax(_el){
		const $el = document.getElementsByClassName(_el);
		const length = $el.length;
		let wH = window.innerHeight;

		window.addEventListener('load', () => {
			for(let i = 0; i < length; i++){
				$el[i].style.height = wH + 'px';
			}
		}, false);

		window.addEventListener('resize', () => {
			wH = window.innerHeight;
			for(let i = 0; i < length; i++){
				$el[i].style.height = wH + 'px';
			}
		}, false);
	}

	singleFade(_el, _offset){
		const targets = document.querySelectorAll(_el);
		let delay;
		let offset = -30;

		if(_offset != undefined){
			offset = _offset;
		}

		const options = {
			root: null,
			rootMargin: `0px 0px ${offset}%`,
			threshold: 0
		};

		const observer = new IntersectionObserver(intersected, options);

		targets.forEach(target => {
			observer.observe(target);
		});

		function intersected(entries) {
			entries.forEach(entry => {
				if(entry.target.dataset.delay){
					delay = entry.target.dataset.delay;
				} else {
					delay = 0;
				}

				if (entry.isIntersecting) {
					setTimeout(() => {
						entry.target.classList.add('is-active');
					}, delay)
				}
			});
		}
	}

	staggerFade(_el, _childEl, _offset){
		const targets = document.querySelectorAll(_el);
		let staggerDelay;
		let delay;
		let offset = -30;

		if(_offset != undefined){
			offset = _offset;
		}

		const options = {
			root: null,
			rootMargin: `0px 0px ${offset}%`,
			threshold: 0
		};

		// console.log(options.rootMargin)

		const observer = new IntersectionObserver(intersected, options);

		targets.forEach(target => {
			observer.observe(target);
		});

		function intersected(entries) {
			entries.forEach(entry => {

				const children = entry.target.querySelectorAll(_childEl);

				if(entry.target.dataset.staggerdelay){
					staggerDelay = entry.target.dataset.staggerdelay;
				} else {
					staggerDelay = 200;
				}

				if(entry.target.dataset.delay){
					delay = entry.target.dataset.delay;
				} else {
					delay = 0;
				}

				if (entry.isIntersecting) {
					setTimeout(() => {
						children.forEach((child,index) => {
							setTimeout(() => {
								child.classList.add('is-active');
							}, index * staggerDelay);
						});
						entry.target.classList.add('id-treated');
					}, delay);
				}
			});
		}
	}


	selectSet(_el,_min,_max){
		const $this = $(_el);

		for(let i = _min; i <= _max; i++){
			let code = `<option value="${i}">${i}</option>`;
			$this.append(code);
		}
	}

	infoEnd(){
		let timer = 0;

		$(window).on('resize', () => {
			clearTimeout(timer);

			timer = setTimeout(() => {

				$('.p-info-end').each((i,el) => {
					const h = $(el).find('.p-sec-header-type-1').outerHeight();

					if(!$('.p-info-end__white-out').length){
						$(el).append('<div class="p-info-end__white-out"></div>');
					}

					$(el).find('.p-info-end__white-out').height($(el).height() - h);
				});
			}, 200);
		}).trigger('resize');
	}

}

// export default (context, inject) => {
//   inject('util', Util)
// }

export { Util };