import OBJ from './_obj';
class ua {
  constructor(){
    OBJ.sOLDIE = false;
    OBJ.isSP = false;
    OBJ.isTABLET = false;
    OBJ.isPC = false;
    OBJ.isSP_VIEW = false;
    OBJ.SCRL_TOP = 0;
    OBJ.TIME = null;
    OBJ.VIEW_PORT = null;
    OBJ.UA = navigator.userAgent;

    if(OBJ.UA.search(/msie [6.]|msie [7.]|msie [8.]|msie [9.]|msie [10.]/i) !=-1){
			OBJ.isOLDIE = true;
			$('body').addClass('is-oldie');
    }

    let uaLower = OBJ.UA.toLowerCase();

    if(uaLower.indexOf('edge/') > -1){
      $('body').addClass('is-edge');
    }else if(uaLower.indexOf('trident/7.0') > -1){
      $('body').addClass('is-ie11');
    }else if(uaLower.indexOf('firefox') > -1){
      $('body').addClass('is-firefox');
    }else if(uaLower.indexOf('chrome') > -1){
      $('body').addClass('is-chrome');
    }else if(uaLower.indexOf('safari') > -1){
      $('body').addClass('is-safari');
    }
    if(OBJ.UA.search(/android/i) !=-1){
      $('body').addClass('is-android');
      // alert('アンドロイド');
    }

		if(uaLower.indexOf('windows nt') > -1){
			$('body').addClass('is-windows');
		}

    if( OBJ.UA.indexOf("iPhone") > 0 ) {
      OBJ.UA.match(/iPhone OS (\w+){1,3}/g);
      var version = (RegExp.$1.replace(/_/g, '')+'00').slice(0,3);
      $('body').addClass('ios'+version);
    }

    if(OBJ.UA.search(/iphone/i) !=-1 || OBJ.UA.search(/ipad/i) !=-1 || OBJ.UA.search(/ipod/i) !=-1){
      $('body').addClass('is-ios');
      // alert('ios');
    }

    // 各種デバイス判定 & meta viewport書き込み
		//////////////////////
		if(OBJ.UA.search(/iphone/i) !=-1 || OBJ.UA.search(/ipod/i) !=-1 || ( OBJ.UA.search(/android/i) !=-1 && OBJ.UA.search(/mobile/i) !=-1)){
      // スマホ判定
      OBJ.isSP = true;
      $('body').addClass('is-sp');

    }else if(OBJ.UA.search(/ipad/i) !=-1 || OBJ.UA.search(/android/i) !=-1){
      // タブレット判定
      OBJ.isTABLET = true;
      $('body').addClass('is-tb');
      // $('meta[name="viewport"]').remove();
      // $('head').append('<meta name="viewport" content="width=1180">');
    }else if(/android|ipod|ipad|iphone|macintosh/.test(uaLower) && 'ontouchend' in document){
       // ipad判定
      OBJ.isTABLET = true;
      $('body').addClass('is-tb');
      // $('meta[name="viewport"]').remove();
      // $('head').append('<meta name="viewport" content="width=1180">');
    }else{
    // PC判定
      OBJ.isPC = true;
      $('body').addClass('is-pc');
			$('.shinjirareru').addClass('is-pc');
    }

    $(window).on('touchstart', () => {
      if($('body').hasClass('is-pc')){
        $('body').removeClass('is-pc').addClass('is-tb')
      }
    });
    // $(window).on('mousemove', () => {
    //   if($('body').hasClass('is-tb')){
    //     $('body').removeClass('is-tb').addClass('is-pc')
    //   }
    // });


  }
}


export { ua };
